import React from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
  return (
    <>
      <div
        className="my-4 py-8 bg-gradient-to-r from-blue-50 to-blue-100"
        id="portfolio"
      >
        <h3 className="my-2 text-center text-3xl primary-color uppercase font-extrabold tracking-wide">
          Our Products
        </h3>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 primary-color mb-8"></div>
        </div>

        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Card 1 */}
            <div className="bg-white transition-transform transform hover:scale-105 duration-300 hover:shadow-2xl rounded-lg p-5 min-h-max border border-gray-200 shadow-md">
              <img
                src="https://projectsly.com/images/blog/best-task-management-system.png?v=1670514978795773617"
                alt="Business Management System"
                className="rounded-lg mb-4 h-auto w-full object-cover"
              />
              <h4 className="font-semibold text-xl md:text-2xl text-center my-2 text-primary">
              Auto Dialer Software  
              </h4> 
              <p className="text-md font-medium leading-5 h-auto md:h-36 text-gray-700">
              Omniserve Infotech auto dialer software helps automatically dial a large number of leads and saves significant time. It enables you to connect a customer to a live agent. This leads to high productivity and efficiency.
              </p>
              <div className="flex justify-center my-4">
                <Link
                  to="/get-demo"
                  className="text-white button inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl bg-blue-600 transition-colors duration-300 hover:bg-blue-700"
                >
                  Schedule Demo
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>

            {/* Card 2 */}
            <div className="bg-white transition-transform transform hover:scale-105 duration-300 hover:shadow-2xl rounded-lg p-5 min-h-max border border-gray-200 shadow-md">
              <img
                src="https://projectsly.com/images/blog/best-task-management-system.png?v=1670514978795773617" // Replace with actual image path
                alt="Business Management System"
                className="rounded-lg mb-4 h-auto w-full object-cover"
              />
              <h4 className="font-semibold text-xl md:text-2xl text-center my-2 text-primary">
              Interactive Voice Response
              </h4>
              <p className="text-md font-medium leading-5 h-auto md:h-36 text-gray-700">
              Omniserve Infotech Interactive Voice Response, or IVR, is an automated telephone system technology that enables callers to receive or provide information or make requests using voice or menu inputs, without speaking to live agents.
              </p>
              <div className="flex justify-center my-4">
                <Link
                  to="/get-demo"
                  className="text-white button inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl bg-blue-600 transition-colors duration-300 hover:bg-blue-700"
                >
                  Schedule Demo
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>

            {/* Card 3 */}
            <div className="bg-white transition-transform transform hover:scale-105 duration-300 hover:shadow-2xl rounded-lg p-5 min-h-max border border-gray-200 shadow-md">
              <img
                src="https://projectsly.com/images/blog/best-task-management-system.png?v=1670514978795773617" // Replace with actual image path
                alt="Business Management System"
                className="rounded-lg mb-4 h-auto w-full object-cover"
              />
              <h4 className="font-semibold text-xl md:text-2xl text-center my-2 text-primary">
              GSM Gateway Device
              </h4>
              <p className="text-md font-medium leading-5 h-auto md:h-36 text-gray-700">
              Omniserve Infotech GSM gateways provide a way to route traffic from a GSM network to another type of network. GSM gateways are often used to route traffic from wireless GSM networks to wired networks.
              </p>
              <div className="flex justify-center my-4">
                <Link
                  to="/get-demo"
                  className="text-white button inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl bg-blue-600 transition-colors duration-300 hover:bg-blue-700"
                >
                  Schedule Demo
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>

            {/* Card 4 */}
            <div className="bg-white transition-transform transform hover:scale-105 duration-300 hover:shadow-2xl rounded-lg p-5 min-h-max border border-gray-200 shadow-md">
              <img
                src="https://projectsly.com/images/blog/best-task-management-system.png?v=1670514978795773617" // Replace with actual image path
                alt="Business Management System"
                className="rounded-lg mb-4 h-auto w-full object-cover"
              />
              <h4 className="font-semibold text-xl md:text-2xl text-center my-2 text-primary">
              CRM Software
              </h4>
              <p className="text-md font-medium leading-5 h-auto md:h-36 text-gray-700">
              Omniserve Infotech CRM software helps you manage customer interaction and relationships. The software can track interactions with customers, such as sales calls, customer service interactions, and marketing emails.
              </p>
              <div className="flex justify-center my-4">
                <Link
                  to="/get-demo"
                  className="text-white button inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl bg-blue-600 transition-colors duration-300 hover:bg-blue-700"
                >
                  Schedule Demo
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;