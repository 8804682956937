import React from "react";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer";

const SEOOptimization = () => {
  return (
    <>
      <NavBar />
      <div className="container mx-auto px-4 py-20">
        {/* Hero Section */}
        <div className="text-center mb-16 mt-12">
          <h1 className="text-4xl sm:text-5xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-6">
            Boost Your Online Visibility with SEO
          </h1>
          <p className="mt-4 text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            Our SEO optimization services help your business rank higher on
            search engines, driving more traffic and conversions.
          </p>
        </div>

        {/* CTA Section */}
        <div className="text-center py-16 bg-gradient-to-r from-green-600 to-green-500 rounded-lg shadow-xl">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-white mb-4">
            Elevate Your Website’s Ranking!
          </h2>
          <p className="text-lg text-white max-w-2xl mx-auto mb-8">
            Unlock the potential of your website with tailored SEO strategies
            that deliver results.
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-green-600 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-green-50"
          >
            Get Started Today
          </a>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mb-20 mt-12">
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Keyword Research
            </h3>
            <p className="text-gray-600">
              We identify the right keywords to target for your business to
              maximize search visibility.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              On-Page SEO
            </h3>
            <p className="text-gray-600">
              Our team optimizes your website’s content and structure for better
              rankings.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Link Building
            </h3>
            <p className="text-gray-600">
              We develop high-quality backlinks to improve your site’s authority
              and search engine ranking.
            </p>
          </div>
        </div>

        {/* Process Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            Our SEO Process
          </h2>
          <div className="flex flex-col md:flex-row justify-center flex-wrap">
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                1. Analysis
              </h3>
              <p className="text-gray-600">
                We conduct a comprehensive analysis of your current SEO
                performance and competitors.
              </p>
            </div>
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                2. Strategy
              </h3>
              <p className="text-gray-600">
                Our team develops a customized SEO strategy tailored to your
                business goals.
              </p>
            </div>
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                3. Implementation
              </h3>
              <p className="text-gray-600">
                We execute the SEO plan with precision, focusing on both on-page
                and off-page optimization.
              </p>
            </div>
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                4. Reporting
              </h3>
              <p className="text-gray-600">
                We provide regular reports on your SEO performance and make
                adjustments as needed.
              </p>
            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            What Our Clients Say
          </h2>
          <div className="space-y-4 max-w-2xl mx-auto">
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Our traffic increased significantly after implementing their SEO
              strategies!"
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Professional and results-driven, they transformed our online
              presence."
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Thanks to their expertise, we are now ranking on the first page
              for our target keywords."
            </blockquote>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="text-center py-16 bg-green-100 rounded-lg shadow-md">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-gray-800 mb-4">
            Let’s Optimize Your Website Together!
          </h2>
          <p className="text-lg md:text-xl text-gray-700 max-w-2xl mx-auto mb-8">
            Our team is ready to take your SEO to the next level. Contact us to
            discuss your project needs.
          </p>
          <a
            href="/contact"
            className="inline-block bg-green-600 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-green-700"
          >
            Contact Us
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SEOOptimization;