import React from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import aboutus from "../images/about-us/about-us.gif";
import missionImage from "../images/about-us/mission.png";
import visionImage from "../images/about-us/vision.png";
import valuesImage from "../images/about-us/values.png";

const AboutUs = () => {
  return (
    <>
      <NavBar />
      <div className="shadow-lg mt-12 pt-6 pb-6 lg:pt-12 lg:pb-12 px-0 text-white w-full">
        {/* About Us */}
        <section
          id="hero"
          className="bg-gradient-to-r from-blue-500 to-indigo-500 shadow-lg mt-4 p-6 lg:p-12 text-white w-full"
          data-aos="fade-up"
        >
          <div className="flex flex-col lg:flex-row justify-between items-center text-center lg:text-left">
            <div className="lg:w-1/2">
              <h1 className="text-4xl font-extrabold mb-4">About Us</h1>
              <p className="text-lg mb-6 leading-relaxed text-justify">
                Omniserve Infotech provides you with cost-effective and
                cutting-edge IT solutions. We are engaged as a service provider
                of lead management software, call center software, and many
                other products. Established in year 2016 our team is
                continuously delivering exceptional solutions and services.
                Customer expectations are evolving and we are here to face the
                challenge of balancing the need. Our strong relationship with
                customers and partners across the globe has allowed us to offer
                the best services to our customers. We are here to provide,
                nothing but excellence to our customers. In this competitive
                field, we are not ready to compromise with the quality of our
                service.
              </p>
              <button className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold shadow-lg transition-transform duration-300 hover:scale-105">
                Download Company Profile
              </button>
            </div>
            <div className="lg:w-2/5 mt-4 lg:mt-0">
              <img
                alt="About Us"
                className="rounded-lg w-full h-auto object-cover transition-transform duration-300 hover:scale-105"
                src={aboutus}
              />
            </div>
          </div>
        </section>

        {/* Our Mission Section */}
        <section className="rounded-lg mt-8 p-6 lg:p-8" data-aos="fade-up">
          <div className="flex flex-col lg:flex-row justify-between items-center text-center lg:text-left">
            <div className="lg:w-1/3 mt-4 lg:mt-0">
              <img
                alt="Our Mission"
                className="rounded-lg w-full h-auto object-cover transition-transform duration-300 hover:scale-105"
                src={missionImage}
              />
            </div>
            <div className="lg:w-1/2 p-6">
              <h2 className="text-4xl font-semibold mb-3 text-blue-500">
                Our Mission
              </h2>
              <p className="text-gray-600 mb-4 leading-relaxed text-justify">
                At Onmiserve Infotech, we are dedicated to delivering
                cutting-edge technology solutions that empower businesses to
                thrive in the digital landscape. Our comprehensive range of
                services includes web and software development, mobile app
                development, digital marketing, and custom CRM solutions. We
                pride ourselves on our commitment to excellence and customer
                satisfaction, working closely with our clients to create
                tailored solutions that drive growth and innovation.
              </p>
            </div>
          </div>
        </section>

        {/* Our Vision Section */}
        <section className="rounded-lg mt-8 p-6 lg:p-8" data-aos="fade-up">
          <div className="flex flex-col lg:flex-row justify-between items-center text-center lg:text-left">
            <div className="lg:w-1/2 p-6">
              <h2 className="text-4xl font-semibold mb-3 text-blue-500">
                Our Vision
              </h2>
              <p className="text-gray-600 mb-4 leading-relaxed">
                To provide our customers with the best IT solutions to transform
                their business and reach new heights. We empower businesses with
                expertise and deliver innovative solutions. Let Omniserve
                Infotech be your guide through the changing business landscape.
                Trusted by more than 500 brands.
              </p>
            </div>
            <div className="lg:w-1/3 mt-4 lg:mt-0">
              <img
                alt="Our Vision"
                className="rounded-lg w-full h-auto object-cover transition-transform duration-300 hover:scale-105"
                src={visionImage}
              />
            </div>
          </div>
        </section>

        {/* Our Values Section */}
        <section className="rounded-lg mt-8 p-6 lg:p-8" data-aos="fade-up">
          <div className="flex flex-col lg:flex-row justify-between items-center text-center lg:text-left">
            <div className="lg:w-1/3 mt-4 lg:mt-0">
              <img
                alt="Our Values"
                className="rounded-lg w-full h-auto object-cover transition-transform duration-300 hover:scale-105"
                src={valuesImage}
              />
            </div>
            <div className="lg:w-1/2 p-6">
              <h2 className="text-4xl font-semibold mb-3 text-blue-500">
                Our Values
              </h2>
              <p className="text-gray-600 mb-4 leading-relaxed">
                At Onmiserve Infotech, our core values guide our actions and
                decision-making:
              </p>
              <ul className="text-gray-600 list-none list-inside mb-6 space-y-2">
                <li>
                  <b>1. Integrity:</b> We uphold the highest standards of
                  honesty and transparency in all our dealings, ensuring trust
                  and accountability.
                </li>
                <li>
                  <b>2. Innovation:</b> We embrace creativity and seek out new
                  ideas to develop cutting-edge solutions that meet the evolving
                  needs of our clients.
                </li>
                <li>
                  <b>3. Collaboration:</b> We believe in the power of teamwork,
                  working together with our clients and within our team to
                  achieve shared success.
                </li>
                <li>
                  <b>4. Customer Focus:</b> We prioritize our clients' needs,
                  striving to exceed their expectations through exceptional
                  service and support.
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
