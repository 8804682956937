import React from "react";
import Slider from "react-slick";
import kws from "../images/clients/kws.png";
import geps from "../images/clients/geps.png";
import protergia from "../images/clients/protergia.png";
import client4 from "../images/clients/geps.png";
import client5 from "../images/clients/geps.png";
import client6 from "../images/clients/geps.png";
import client7 from "../images/clients/geps.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Clients = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="mt-8 bg-gray-100">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            Our Clients
          </h2>
          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            Some of our trusted partners.
          </h2>
        </div>
        <div className="p-16" data-aos="fade-in" data-aos-delay="600">
          <Slider {...settings} className="px-4">
            <img src={kws} alt="client1" className="w-32 h-32 object-contain mx-auto" />
            <img src={geps} alt="client2" className="w-32 h-32 object-contain mx-auto" />
            <img src={protergia} alt="client3" className="w-32 h-32 object-contain mx-auto" />
            <img src={client4} alt="client4" className="w-32 h-32 object-contain mx-auto" />
            <img src={client5} alt="client5" className="w-32 h-32 object-contain mx-auto" />
            <img src={client6} alt="client6" className="w-32 h-32 object-contain mx-auto" />
            <img src={client7} alt="client7" className="w-32 h-32 object-contain mx-auto" />
          </Slider>
        </div>
      </section>
    </div>
  );
};

export default Clients;