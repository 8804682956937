import React from "react";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer";

const WebDevelopment = () => {
  return (
    <>
      <NavBar />
      <div className="container mx-auto px-4 py-20">
        {/* Hero Section */}
        <div className="text-center mb-16 mt-12">
          <h1 className="text-4xl sm:text-5xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-6">
            Exceptional Web Development Solutions
          </h1>
          <p className="mt-4 text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            We create responsive, high-quality websites tailored to your unique
            business needs. Let us help you make a strong online presence with
            innovative solutions.
          </p>
        </div>

        {/* CTA Section */}
        <div className="text-center py-16 bg-gradient-to-r from-blue-600 to-blue-500 rounded-lg shadow-xl">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-white mb-4">
            Elevate Your Business Online!
          </h2>
          <p className="text-lg text-white max-w-2xl mx-auto mb-8">
            Take your digital presence to the next level with a website that
            combines exceptional design and flawless functionality.
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-blue-600 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-blue-50"
          >
            Get Started Today
          </a>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mb-20 mt-12">
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Responsive Design
            </h3>
            <p className="text-gray-600">
              Our websites are designed to look great on any device, ensuring an
              optimal user experience.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              SEO Friendly
            </h3>
            <p className="text-gray-600">
              We implement best SEO practices to help your website rank higher
              on search engines.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Fast Load Times
            </h3>
            <p className="text-gray-600">
              Our websites are optimized for speed to provide a smooth
              experience for users.
            </p>
          </div>
        </div>

        {/* Process Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            Our Development Process
          </h2>
          <div className="flex flex-col md:flex-row justify-center flex-wrap">
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                1. Consultation
              </h3>
              <p className="text-gray-600">
                We discuss your project requirements and goals to understand
                your vision.
              </p>
            </div>
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                2. Design
              </h3>
              <p className="text-gray-600">
                Our design team creates mockups and prototypes for your review.
              </p>
            </div>
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                3. Development
              </h3>
              <p className="text-gray-600">
                Our developers bring the designs to life with robust coding
                practices.
              </p>
            </div>
            <div className="bg-blue-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-blue-600 mb-2">
                4. Launch
              </h3>
              <p className="text-gray-600">
                We deploy your website and ensure it performs flawlessly.
              </p>
            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            What Our Clients Say
          </h2>
          <div className="space-y-4 max-w-2xl mx-auto">
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Thanks to the team, our website has transformed our online
              presence!"
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "The level of professionalism and creativity exceeded our
              expectations."
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Highly recommend! Our traffic and engagement have skyrocketed
              since the redesign."
            </blockquote>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="text-center py-16 bg-blue-100 rounded-lg shadow-md">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-gray-800 mb-4">
            Let’s Build Something Great Together!
          </h2>
          <p className="text-lg md:text-xl text-gray-700 max-w-2xl mx-auto mb-8">
            Our team is here to support you at every step of the way. Contact us
            to discuss your project needs.
          </p>
          <a
            href="/contact"
            className="inline-block bg-blue-600 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-blue-700"
          >
            Contact Us
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WebDevelopment;