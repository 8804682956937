import React from "react";
import "../default.css";
import whatsappSVG from "../images/Extra-img/wtsapp.png";

const WhatsAppButton = () => {
  return (
    <a href="https://wa.me/yourwhatsappnumber" target="_blank" rel="noopener noreferrer" className="whatsapp-button">
      <img src={whatsappSVG} alt="WhatsApp" className="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppButton;