import React from "react";
import img from "../images/Web-developer.svg";
import { Link } from "react-router-dom";

const Intro = () => {
  return (
    <div className="m-auto p-4 md:p-12 h-auto w-[90vw] lg:h-[80vh]" id="about">
      <div
        className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left"
        data-aos="fade-up"
      >
        {/* Image Section */}
        <div className="lg:w-1/2 flex flex-col justify-center lg:mx-4">
          <img
            alt="Web Developer"
            className="rounded-lg transition-transform duration-300 transform hover:scale-105"
            src={img}
          />
        </div>

        {/* Text Section */}
        <div
          className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8"
          data-aos="zoom-in"
          data-aos-delay="500"
        >
          <h3 className="text-4xl font-bold text-gray-800 leading-snug">
            We develop high-quality web and mobile applications for
            organizations, institutions, and SMEs
          </h3>
          <p className="my-3 text-xl text-gray-600 font-semibold">
            Our team is well-versed in software development and is ready to help
            develop the applications of your choice.
          </p>
          <p className="my-3 text-xl text-gray-600 font-semibold">
            We take responsibility for building custom software solutions that
            cater to the automation of your business processes and improve
            efficiency.
          </p>
          <Link
            to="/about-us"
            className="text-white inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg bg-blue-600 hover:bg-blue-700 transition duration-300 shadow-lg rounded-full sm:w-auto button"
          >
            Read More
            <svg
              className="w-4 h-4 ml-1 transform transition-transform duration-300 group-hover:translate-x-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Intro;