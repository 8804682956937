import React from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";

const ProductPage = () => {
  const products = [
    {
      id: 1,
      title: "Gateway devices",
      description:
        "Omniserve Infotech GSM gateways provide a way to route traffic from a GSM network to another type of network. GSM gateways are often used to route traffic from wireless GSM networks to wired networks.",
      imageUrl:
        "https://5.imimg.com/data5/SELLER/Default/2024/6/425558386/VA/PQ/XF/2628310/gsm-gateway-devices-500x500.jpg",
    },
    {
      id: 2,
      title: "CRM Software",
      description:
        "Omniserve Infotech CRM software helps you manage customer interaction and relationships. The software can track interactions with customers, such as sales calls, customer service interactions, and marketing emails.",
      imageUrl:
        "https://www.sigmasoftwares.org/img/best-crm-software-500x500.png",
    },
    {
      id: 3,
      title: "Interactive Voice Response System",
      description:
        "Omniserve Infotech Interactive Voice Response, or IVR, is an automated telephone system technology that enables callers to receive or provide information or make requests using voice or menu inputs,",
      imageUrl: "https://cdn.botpenguin.com/assets/website/IVR_b5f68eb519.webp",
    },
    {
      id: 4,
      title: "Auto Dialer Software",
      description:
        "Omniserve Infotech auto dialer software helps automatically dial a large number of leads and saves significant time. It enables you to connect a customer to a live agent. This leads to high productivity and efficiency.",
      imageUrl:
        "https://www.wpbeginner.com/wp-content/uploads/2022/03/best-auto-dialer-software-og.png",
    },
  ];

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-auto mt-16"
        data-aos="zoom-in"
      >
        <div id="hero" className="flex flex-col py-8 text-center">
          <h1 className="text-4xl font-bold mb-4 text-blue-600">
            Our Products
          </h1>
          <p className="text-lg mb-8 text-gray-700">
            "Empowering businesses with cutting-edge digital solutions that
            drive growth, enhance efficiency, and elevate customer experiences."
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map((product) => (
            <div
              key={product.id}
              className="bg-white shadow-xl rounded-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-2xl duration-500" // Slowed down hover effect
            >
              <img
                src={product.imageUrl}
                alt={product.title}
                className="w-full h-48 object-cover transition-transform duration-500 hover:scale-110"
              />
              <div className="p-6 text-center">
                <h2 className="text-2xl font-semibold mb-2 text-gray-800">
                  {product.title}
                </h2>
                <p className="text-gray-600 mb-4">{product.description}</p>
                <button className="bg-blue-600 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
                  Explore
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductPage;
