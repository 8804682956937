import React from "react";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer";

const EmailMarketing = () => {
  return (
    <>
      <NavBar />
      <div className="container mx-auto px-4 py-20">
        {/* Hero Section */}
        <div className="text-center mb-16 mt-12">
          <h1 className="text-4xl sm:text-5xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-6">
            Boost Your Sales with Email Marketing
          </h1>
          <p className="mt-4 text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            Our email marketing services help your business engage customers,
            nurture leads, and drive sales through targeted campaigns.
          </p>
        </div>

        {/* CTA Section */}
        <div className="text-center py-16 bg-gradient-to-r from-green-600 to-green-500 rounded-lg shadow-xl">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-white mb-4">
            Transform Your Email Strategy!
          </h2>
          <p className="text-lg text-white max-w-2xl mx-auto mb-8">
            Unlock the potential of your brand with personalized email marketing
            strategies that deliver measurable results.
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-green-600 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-green-50"
          >
            Get Started Today
          </a>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mb-20 mt-12">
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Targeted Campaigns
            </h3>
            <p className="text-gray-600">
              We create targeted email campaigns that reach your audience with
              the right message at the right time.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Personalized Content
            </h3>
            <p className="text-gray-600">
              Our team crafts personalized email content that resonates with
              your subscribers and drives engagement.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Performance Tracking
            </h3>
            <p className="text-gray-600">
              We provide detailed analytics and insights to help you understand
              your campaign performance and optimize results.
            </p>
          </div>
        </div>

        {/* Process Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            Our Email Marketing Process
          </h2>
          <div className="flex flex-col md:flex-row justify-center flex-wrap">
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                1. Strategy Development
              </h3>
              <p className="text-gray-600">
                We analyze your goals and create a tailored email marketing
                strategy that aligns with your business objectives.
              </p>
            </div>
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                2. List Segmentation
              </h3>
              <p className="text-gray-600">
                Our team segments your email list to target specific groups with
                relevant messages for improved engagement.
              </p>
            </div>
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                3. Campaign Execution
              </h3>
              <p className="text-gray-600">
                We design and execute email campaigns that are visually
                appealing and optimized for all devices.
              </p>
            </div>
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                4. Performance Analysis
              </h3>
              <p className="text-gray-600">
                We analyze campaign results and provide actionable insights to
                continuously improve your email marketing efforts.
              </p>
            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            What Our Clients Say
          </h2>
          <div className="space-y-4 max-w-2xl mx-auto">
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Our open rates have doubled since we started working with this
              team!"
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "They crafted an email campaign that truly resonated with our
              audience."
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Thanks to their expertise, we've seen a significant increase in
              our sales through email marketing."
            </blockquote>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="text-center py-16 bg-green-100 rounded-lg shadow-md">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-gray-800 mb-4">
            Let’s Elevate Your Email Marketing Together!
          </h2>
          <p className="text-lg md:text-xl text-gray-700 max-w-2xl mx-auto mb-8">
            Our team is ready to enhance your email marketing efforts. Contact
            us to discuss your project needs.
          </p>
          <a
            href="/contact"
            className="inline-block bg-green-600 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-green-700"
          >
            Contact Us
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmailMarketing;