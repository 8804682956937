import React from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import UnderConstruction from "../images/under-cons/under-construction.png";

const TermsOfServices = () => {
  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="flex flex-col items-center justify-center py-16 px-4 md:px-16 lg:px-32 min-h-screen bg-white mt-16">
        <div className="text-center">
          <h1 className="text-5xl font-extrabold text-blue-600 mb-4 animate-bounce">
            Page Under Construction
          </h1>
          <p className="text-xl text-gray-700 mb-8">
            We are working hard to bring you something amazing. Stay tuned!
          </p>
          <div className="flex justify-center mb-8">
            {" "}
            {/* Centering the image */}
            <img
              src={UnderConstruction}
              alt="Under Construction"
              className="w-80 h-auto rounded-lg transition duration-500 transform hover:scale-105"
            />
          </div>
        </div>
        <div className="mt-12">
          <a
            href="/"
            className="bg-blue-600 text-white font-bold py-3 px-6 rounded-lg shadow-lg transition duration-300 transform hover:bg-blue-700 hover:scale-105"
          >
            Go Back Home
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfServices;
