import React, { useState } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import axios from "axios";
import Notiflix from "notiflix";

const Contact = () => {
  useDocTitle("Omniserve Infotech - Contact Us");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  const clearErrors = () => {
    setErrors([]);
  };

  const clearInput = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const sendEmail = (e) => {
    e.preventDefault();
    document.getElementById("submitBtn").disabled = true;
    document.getElementById("submitBtn").innerHTML = "Loading...";
    let fData = new FormData();
    fData.append("first_name", firstName);
    fData.append("last_name", lastName);
    fData.append("email", email);
    fData.append("phone_number", phone);
    fData.append("message", message);

    axios({
      method: "post",
      url: process.env.REACT_APP_CONTACT_API,
      data: fData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        document.getElementById("submitBtn").disabled = false;
        document.getElementById("submitBtn").innerHTML = "Send Message";
        clearInput();
        Notiflix.Report.success("Success", response.data.message, "Okay");
      })
      .catch(function (error) {
        document.getElementById("submitBtn").disabled = false;
        document.getElementById("submitBtn").innerHTML = "Send Message";
        const { response } = error;
        if (response.status === 500) {
          Notiflix.Report.failure(
            "An error occurred",
            response.data.message,
            "Okay"
          );
        }
        if (response.data.errors !== null) {
          setErrors(response.data.errors);
        }
      });
  };

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="contact"
        className="flex justify-center items-center mt-8 w-full bg-gradient-to-b from-white via-blue-50 to-blue-100 py-12 lg:py-24"
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <form onSubmit={sendEmail}>
            <div className="w-full bg-white bg-opacity-90 p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-lg">
              <div className="flex justify-between items-center mb-6">
                <h1 className="font-bold text-blue-500 uppercase text-2xl">
                  Send Us a Message
                </h1>
              </div>
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="first_name"
                    className="text-blue-500 font-semibold"
                  >
                    First Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="first_name"
                    name="first_name"
                    className="w-full bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                    type="text"
                    placeholder="Enter your first name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    onKeyUp={clearErrors}
                  />
                  {errors && (
                    <p className="text-red-500 text-sm">{errors.first_name}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="last_name"
                    className="text-blue-500 font-semibold"
                  >
                    Last Name<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="last_name"
                    name="last_name"
                    className="w-full bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                    type="text"
                    placeholder="Enter your last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    onKeyUp={clearErrors}
                  />
                  {errors && (
                    <p className="text-red-500 text-sm">{errors.last_name}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="text-blue-500 font-semibold"
                  >
                    Email<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="email"
                    name="email"
                    className="w-full bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={clearErrors}
                  />
                  {errors && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="phone_number"
                    className="text-blue-500 font-semibold"
                  >
                    Phone<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="phone_number"
                    name="phone_number"
                    className="w-full bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                    type="number"
                    placeholder="Enter your phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onKeyUp={clearErrors}
                  />
                  {errors && (
                    <p className="text-red-500 text-sm">
                      {errors.phone_number}
                    </p>
                  )}
                </div>
              </div>
              <div className="my-4">
                <label
                  htmlFor="message"
                  className="text-blue-500 font-semibold"
                >
                  Remarks<span className="text-red-500">*</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Type your message here"
                  className="w-full h-20 bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyUp={clearErrors}
                ></textarea>
                {errors && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>
              <div className="my-4">
                <label
                  htmlFor="product"
                  className="text-blue-500 font-semibold"
                >
                  Select Your Product <span className="text-red-500">*</span>
                </label>
                <select
                  id="product"
                  name="product"
                  className="w-full bg-white text-gray-900 mt-2 p-4 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 transition duration-200 ease-in-out"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyUp={clearErrors}
                >
                  <option value="" disabled>
                    Product Category
                  </option>
                  <option value="Web Design & Development">
                    Web Design & Development
                  </option>
                  <option value="Mobile App Development">
                    Mobile App Development
                  </option>
                  <option value="Domain & Hosting">Domain & Hosting</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="UI/UX Design">UI/UX Design</option>
                </select>
                {errors && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>
              <div className="my-2 w-1/2 lg:w-2/4">
                <button
                  type="submit"
                  id="submitBtn"
                  className="uppercase text-sm font-bold tracking-wide bg-gradient-to-r from-blue-500 to-blue-700 hover:bg-blue-600 text-white p-4 rounded-lg w-full transition duration-200 ease-in-out transform hover:scale-105 shadow-md button"
                >
                  Send Message
                </button>
              </div>
            </div>
          </form>
          <div className="w-full lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 text-white rounded-2xl shadow-lg">
            <div className="flex flex-col">
              <div className="flex items-start my-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-10 mr-4" // Adjust size as needed
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 10.5h-3M12 9v6m0 0h3.75m-3.75 0H10.5m9 0A9 9 0 116.75 8.25m12.25 3.75h.008v.008h-.008v-.008z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.32 4.11a1.5 1.5 0 00-1.61-.25 1.5 1.5 0 00-.62 1.97c.17.33 1.16 2.27 3.09 4.25 1.93 1.98 3.91 2.9 4.25 3.09a1.5 1.5 0 001.97-.62 1.5 1.5 0 00-.25-1.61l-1.5-1.5a1.5 1.5 0 00-1.61-.25A10.01 10.01 0 016.32 4.11z"
                  />
                </svg>
                <div className="flex flex-col">
                  <h1 className="text-2xl font-bold">Contact Details</h1>
                  <p className="my-1">
                    <span className="font-semibold">Phone:</span> 9990973790
                  </p>
                  <p className="my-1">
                    <span className="font-semibold">Email:</span>{" "}
                    info@omniserveinfotech.com
                  </p>
                </div>
              </div>
              <div className="flex items-start my-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-16 h-6 mr-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 2.25c-3.75 0-6.75 3-6.75 6.75 0 5.25 6.75 12 6.75 12s6.75-6.75 6.75-12C18.75 5.25 15.75 2.25 12 2.25zM12 10.5c-1.125 0-2.25.75-2.25 2.25S10.875 15 12 15s2.25-.75 2.25-2.25S13.125 10.5 12 10.5z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 12h4.5M21 12v-4.5M21 12v4.5"
                  />
                </svg>

                <div className="flex flex-col">
                  <h1 className="text-2xl font-bold">Visit Us</h1>
                  <p className="my-1">
                    <span className="font-semibold">Address:</span> T-1/103,
                    Signature Homes, Raj Nagar Extension, Ghaziabad-201017,
                    Uttar Pradesh, India
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
