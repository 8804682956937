import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/Navbar/NavBar";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Images
import webDevImg from "../images/home-slider/Digital-marketing.jpg";
import digitalMarketingImg from "../images/home-slider/web-dev.jpg";
import crmSoftwareImg from "../images/home-slider/crm-software.jpg";

const Hero = () => {
  return (
    <div className="hero relative w-full h-screen overflow-hidden bg-gray-100">
      <NavBar className="bg-white bg-opacity-90 shadow-lg" />
      <div className="absolute inset-0">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          autoplay={{ delay: 4500 }}
          loop={true}
          className="h-full"
        >
          <SwiperSlide>
            <div className="relative h-full w-full">
              <img
                src={webDevImg}
                alt="Web Development"
                className="w-full h-full object-cover brightness-75"
              />
              <div className="absolute inset-0 flex flex-col justify-center items-center text-white bg-gradient-to-b from-white/30 to-transparent h-3/4">
                <h3 className="bg-black bg-opacity-200 text-4xl font-bold mb-4 text-yellow-500 shadow-md p-4 rounded">
                  Digital Marketing
                </h3>
                <p className="text-xl mb-6 text-gray-200 shadow-md bg-black bg-opacity-150 p-2 rounded">
                  "Transforming clicks into loyal customers through innovative
                  digital strategies."
                </p>
                <Link
                  to="/services"
                  className="inline-flex items-center px-8 py-4 text-lg font-semibold text-yellow-500 bg-white rounded-full shadow-lg hover:bg-yellow-500 hover:text-white transition duration-300 ease-in-out"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="relative h-full w-full">
              <img
                src={digitalMarketingImg}
                alt="Digital Marketing"
                className="w-full h-full object-cover brightness-75"
              />
              <div className="absolute inset-0 flex flex-col justify-center items-center text-white bg-gradient-to-b from-white/30 to-transparent h-3/4">
                <h3 className="bg-black bg-opacity-200 text-4xl font-bold mb-4 shadow-md p-4 rounded text-green-600">
                  Web Design & Development
                </h3>
                <p className="text-xl mb-6 text-gray-200 shadow-md bg-black bg-opacity-80 p-2 rounded">
                  "As the best in web design and development, we transform your
                  vision into beautifully crafted websites."
                </p>
                <Link
                  to="/services"
                  className="inline-flex items-center px-8 py-4 text-lg font-semibold text-green-600 bg-white rounded-full shadow-lg hover:bg-green-600 hover:text-white transition duration-300 ease-in-out"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="relative h-full w-full">
              <img
                src={crmSoftwareImg}
                alt="CRM Software"
                className="w-full h-full object-cover brightness-75"
              />
              <div className="absolute inset-0 flex flex-col justify-center items-center text-white bg-gradient-to-b from-white/30 to-transparent h-3/4">
                <h3 className="bg-black bg-opacity-200 text-4xl font-bold mb-4 shadow-md p-4 rounded text-blue-600">
                  CRM Software
                </h3>
                <p className="text-xl mb-6 text-gray-200 shadow-md bg-black bg-opacity-80 p-2 rounded">
                  "Elevate your customer engagement to new heights with our
                  best-in-class CRM software."
                </p>
                <Link
                  to="/products"
                  className="inline-flex items-center px-8 py-4 text-lg font-semibold text-blue-600 bg-white rounded-full shadow-lg hover:bg-blue-600 hover:text-white transition duration-300 ease-in-out"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <style jsx>{`
        .swiper-button-next,
        .swiper-button-prev {
          width: 30px; /* Adjust the width */
          height: 30px; /* Adjust the height */
          background-color: rgba(
            255,
            255,
            255,
            0.7
          ); /* Optional: background color */
          border-radius: 50%; /* Optional: make them circular */
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .swiper-button-next::after,
        .swiper-button-prev::after {
          font-size: 16px; /* Adjust icon size */
          color: #000; /* Icon color */
        }

        .swiper-button-next:hover,
        .swiper-button-prev:hover {
          background-color: rgba(
            255,
            255,
            255,
            1
          ); /* Optional: hover background color */
        }
      `}</style>
    </div>
  );
};

export default Hero;