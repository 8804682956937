import React from "react";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import "../../navbar.css";

const NavLinks = () => {
  const location = useLocation(); // Get current location

  return (
    <>
      <HashLink className={`nav-links ${location.pathname === "/" ? "active" : ""}`} smooth to="/">Home</HashLink>
      <HashLink className={`nav-links ${location.pathname === "/services" ? "active" : ""}`} smooth to="/services">Services</HashLink>
      <HashLink className={`nav-links ${location.pathname === "/products" ? "active" : ""}`} smooth to="/products">Products</HashLink>
      <HashLink className={`nav-links ${location.pathname === "/about-us" ? "active" : ""}`} smooth to="/about-us">About</HashLink>
      <HashLink className={`nav-links ${location.pathname === "/contact" ? "active" : ""}`} to="/contact">Contact Us</HashLink>
      <HashLink className={`nav-links-default button ${location.pathname === "/get-demo" ? "active" : ""}`} smooth to="/get-demo">
        Start Query
      </HashLink>
    </>
  );
};

export default NavLinks;