import React from "react";
import NavBar from "../../components/Navbar/NavBar";
import Footer from "../../components/Footer";

const WebHosting = () => {
  return (
    <>
      <NavBar />
      <div className="container mx-auto px-4 py-20">
        {/* Hero Section */}
        <div className="text-center mb-16 mt-12">
          <h1 className="text-4xl sm:text-5xl md:text-5xl font-extrabold text-gray-900 leading-tight mb-6">
            Reliable & Scalable Web Hosting Services
          </h1>
          <p className="mt-4 text-lg sm:text-xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
            Our web hosting solutions provide businesses with secure, scalable,
            and reliable hosting environments to ensure seamless online presence
            and growth.
          </p>
        </div>

        {/* CTA Section */}
        <div className="text-center py-16 bg-gradient-to-r from-green-600 to-green-500 rounded-lg shadow-xl">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-white mb-4">
            Get Your Website Online Today!
          </h2>
          <p className="text-lg text-white max-w-2xl mx-auto mb-8">
            Our hosting plans offer the performance and reliability you need to
            succeed online. Choose the best plan for your business and get
            started today.
          </p>
          <a
            href="/contact"
            className="inline-block bg-white text-green-600 font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-green-50"
          >
            Get Started Now
          </a>
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 mb-20 mt-12">
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Fast & Reliable Hosting
            </h3>
            <p className="text-gray-600">
              Our hosting services provide high uptime and fast performance to
              ensure your website runs smoothly at all times.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              Scalable Plans
            </h3>
            <p className="text-gray-600">
              We offer a variety of hosting plans that can scale with your
              growing business needs, from small websites to large enterprises.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-6 transition-transform duration-300 transform hover:scale-105">
            <h3 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
              24/7 Support
            </h3>
            <p className="text-gray-600">
              Our expert support team is available 24/7 to assist you with any
              hosting issues, ensuring minimal downtime for your business.
            </p>
          </div>
        </div>

        {/* Process Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            Our Web Hosting Process
          </h2>
          <div className="flex flex-col md:flex-row justify-center flex-wrap">
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                1. Choose Your Plan
              </h3>
              <p className="text-gray-600">
                Select the hosting plan that best suits your website's
                requirements, from shared hosting to VPS and dedicated servers.
              </p>
            </div>
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                2. Setup & Migration
              </h3>
              <p className="text-gray-600">
                We assist with setting up your hosting environment or migrating
                your existing website to our servers with minimal downtime.
              </p>
            </div>
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                3. Optimize Performance
              </h3>
              <p className="text-gray-600">
                Our team ensures your website is optimized for speed and
                security to provide the best possible experience for your
                visitors.
              </p>
            </div>
            <div className="bg-green-100 rounded-lg shadow-md p-6 mx-4 mb-4 md:mb-0 flex-1">
              <h3 className="text-xl md:text-2xl font-bold text-green-600 mb-2">
                4. Ongoing Monitoring & Support
              </h3>
              <p className="text-gray-600">
                We continuously monitor your website and provide ongoing support
                to keep it secure, fast, and available to your audience.
              </p>
            </div>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="text-center mb-20">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-semibold text-gray-800 mb-8">
            What Our Clients Say
          </h2>
          <div className="space-y-4 max-w-2xl mx-auto">
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Their hosting services have been incredibly reliable and fast. We
              never experience downtime!"
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "Switching to their hosting services was the best decision we made
              for our website."
            </blockquote>
            <blockquote className="text-lg md:text-xl text-gray-600 italic">
              "The support team is always there to help. Our website runs
              smoothly thanks to their expertise."
            </blockquote>
          </div>
        </div>

        {/* Final CTA Section */}
        <div className="text-center py-16 bg-green-100 rounded-lg shadow-md">
          <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold text-gray-800 mb-4">
            Get the Best Web Hosting for Your Business!
          </h2>
          <p className="text-lg md:text-xl text-gray-700 max-w-2xl mx-auto mb-8">
            We offer reliable and secure hosting solutions tailored to meet the
            unique needs of your business. Contact us today to get started!
          </p>
          <a
            href="/contact"
            className="inline-block bg-green-600 text-white font-semibold py-3 px-8 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-110 hover:bg-green-700"
          >
            Contact Us
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WebHosting;
